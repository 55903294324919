import IconText from "components/IconText";

import Map from "assets/img/map.png";
import IdCard from "assets/img/idcard.png";
import Plane from "assets/img/plane.png";
import Money from "assets/img/money.png";
import House from "assets/img/house.png";
import Brain from "assets/img/brain.png";
import Files from "assets/img/files.png";
import Heart from "assets/img/heart.png";
import TwistyArrow from "assets/img/twisty_arrow.png";

export default function HeroSection() {
  return (
    <div id="heroSection" className="flex flex-col">
      <div className="flex w-full text-left">
        <img src={Map} alt="Map" className="h-24 w-auto" />
        <p className="pl-4 text-6xl font-bold">
          Essential Data for{" "}
          <span className="text-gradient inline-block bg-gradient-to-r from-[#d985ee] to-[#541a64] bg-clip-text leading-[5rem] text-[#780794] lg:inline">
            Digital Nomads
          </span>
        </p>
      </div>
      <p className="text-3xl xs:text-2xl">
        Figure out where you’re heading next without having to spend hours
        researching.
      </p>
      <div className="flex flex-row">
        <div className="mr-16 pt-6">
          <IconText icon={IdCard}>Visa Exemption Info</IconText>
          <IconText icon={Plane}>Best Flight Options</IconText>
          <IconText icon={Money}>Cost of Living</IconText>
          <IconText icon={House}>Accommodation Prices</IconText>
          <IconText icon={Brain}>Next Destination Recommendations</IconText>
          <IconText icon={Files}>Loads of Useful Information</IconText>
          <IconText icon={Heart}>Made with Love by Digital Nomads</IconText>
        </div>
        <div className="w-24 2xs:w-36 translate-y-56 2xs:translate-y-32">
          <img src={TwistyArrow} alt="Twisty Arrow" />
        </div>
      </div>
    </div>
  );
}
