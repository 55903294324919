import MapFade from "assets/img/map_fade.png";

export default function TagLine() {
  return (
    <div id="worldMap" className="relative w-full">
      <img
        src={MapFade}
        alt="World Map"
        className="h-auto w-full px-20 opacity-10"
      />
      <p className="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2 transform text-center text-5xl font-bold">
        Trusted by adventure seekers
        <div className="mt-4 flex flex-row justify-center">
          <p className="relative inline-flex items-center bg-[#9E00FF] px-2 pb-2 pr-4 pt-1 text-5xl leading-none">
            all over the globe
            <span className="flash absolute bottom-0 right-0 top-0 w-[0.4rem] bg-white"></span>
          </p>
        </div>
      </p>
    </div>
  );
}
