import AppButton from "components/AppButton";
import Earth from "assets/img/earth.png";

export default function CallToAction() {
  return (
    <div className="relative flex flex-col items-center justify-center">
      <p className="text-gradient relative z-20 bg-gradient-to-r from-[#d985ee] to-[#541a64] bg-clip-text text-7xl font-bold">
        Unlock the World
      </p>

      <p className="z-20 mt-4 text-4xl font-bold">Start your journey now</p>
      <img
        src={Earth}
        alt="Earth"
        className="z-10 h-auto translate-y-4 lg:w-[70vw]"
      />
      <div className="absolute z-20">
        <AppButton className="mt-16">
          <p className="text-2xl xs:text-xl font-bold">Launch DriftPlanner</p>
        </AppButton>
      </div>
    </div>
  );
}
