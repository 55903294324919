import React, { useState, useEffect } from "react";
import ReactCardFlip from "react-card-flip";

import IconText from "components/IconTextShadow";
import IdCard from "assets/img/idcard.png";
import Plane from "assets/img/plane.png";
import Hotel from "assets/img/hotel.png";
import House from "assets/img/house.png";
import Sun from "assets/img/sun.png";
import Clock from "assets/img/clock.png";
import TapeTap from "assets/img/tape_tap.png";
import TapeHover from "assets/img/tape_hover.png";

export default function LocationCard({
  image,
  country,
  city,
  visa,
  temperature,
  timezone,
  quick_flight,
  hotel_price,
  airbnb_price,
}) {
  const [isFlipped, setIsFlipped] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 767);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleMouseEnter = () => {
    if (!isSmallScreen) {
      setIsFlipped(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isSmallScreen) {
      setIsFlipped(false);
    }
  };

  const handleClick = () => {
    if (isSmallScreen) {
      setIsFlipped((prev) => !prev);
    }
  };

  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
      <div
        className="h-[36rem] w-[24rem] 2xs:h-[33rem] 2xs:w-[22rem] xs:h-96 xs:w-64 rounded-2xl bg-cover bg-center"
        style={{ backgroundImage: `url(${image})` }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        <div className="absolute inset-0 rounded-2xl bg-black opacity-20"></div>
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="-translate-y-20 text-center">
            <p className="text-shadow-100 text-6xl 2xs:text-5xl xs:text-4xl font-bold">{city}</p>
            <p className="text-shadow-100 text-4xl 2xs:text-3xl xs:text-2xl font-bold">{country}</p>
            <img src={isSmallScreen ? TapeTap : TapeHover} alt="Tape" className="h-20 xs:h-16 translate-y-28 w-auto mx-auto" />
          </div>
        </div>
      </div>

      <div
        className="h-[36rem] w-[24rem] 2xs:h-[33rem] 2xs:w-[22rem] xs:h-96 xs:w-64 rounded-2xl bg-cover bg-center"
        style={{ backgroundImage: `url(${image})` }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        <div className="absolute inset-0 rounded-2xl bg-black opacity-40"></div>
        <div className="absolute inset-0 mx-12 xs:mx-8 my-6 flex flex-col space-y-4 2xs:space-y-2 xs:space-y-0">
          <p className="text-shadow-100 mb-8 xs:mb-2 text-center text-5xl xs:text-4xl font-bold">
            {city}
          </p>
          <IconText icon={IdCard} alt="ID Card">
            <p className="text-shadow-100 text-4xl 2xs:text-3xl xs:text-2xl">{visa}</p>
          </IconText>
          <IconText icon={Sun} alt="Sun">
            <p className="text-shadow-100 text-4xl 2xs:text-3xl xs:text-2xl">{temperature}</p>
          </IconText>
          <IconText icon={Clock} alt="Clock">
            <p className="text-shadow-100 text-4xl 2xs:text-3xl xs:text-2xl">{timezone}</p>
          </IconText>
          <IconText icon={Plane} alt="Plane">
            <p className="text-shadow-100 text-4xl 2xs:text-3xl xs:text-2xl">{quick_flight}</p>
          </IconText>
          <IconText icon={Hotel} alt="Hotel">
            <p className="text-shadow-100 text-4xl 2xs:text-3xl xs:text-2xl">{hotel_price}</p>
          </IconText>
          <IconText icon={House} alt="House">
            <p className="text-shadow-100 text-4xl 2xs:text-3xl xs:text-2xl">{airbnb_price}</p>
          </IconText>
        </div>
      </div>
    </ReactCardFlip>
  );
}
