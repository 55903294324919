export default function IconText({ icon, alt, children, className }) {
  return (
    <div className={`flex flex-row items-center ${className}`}>
      <img
        src={icon}
        alt={alt}
        className="drop-shadow-100 h-auto w-10 py-1"
      ></img>
      <p className="pl-2 text-2xl">{children}</p>
    </div>
  );
}
