import Pin from "assets/img/pin.png";
import Preview from "assets/img/app_preview.png";
import IconTextTitle from "components/IconTextTitle";

export default function AppPreview() {
  return (
    <div  className="flex w-full flex-col items-center">
      <IconTextTitle 
        image={Pin}
        alt="Pin"
        text="Detailed City Breakdowns"
        description="We’ve painstakingly researched 490+ cities and condensed the information down into an easy to read format."
      />
      <img src={Preview} alt="App Preview" className="mt-8"/>
    </div>
  );
}
