export default function IconTextTitle({ image, alt, text, description }) {
  return (
    <div>
      <div className="flex justify-center flex-col items-center">
        <span className="text-5xl font-bold text-center">
          <img src={image} alt={alt} className="inline-block h-auto w-16 mr-2 align-middle" />
          {text}
        </span>
        <span className="text-3xl xs:text-2xl lg:text-3xl text-center w-[90%] md:w-[70%] mt-4">
          {description}
        </span>
      </div>
    </div>
  )
}
