import React from "react";
import "./LandingPage.css";
import LogoFull from "components/LogoFull";
import AppButton from "components/AppButton";
import Container from "components/Container";
import Header from "components/pages/LandingPage/Header";
import HeroSection from "components/pages/LandingPage/HeroSection";
import TagLine from "components/pages/LandingPage/TagLine";
import AppPreview from "components/pages/LandingPage/AppPreview";
import RecommendationsPreview from "components/pages/LandingPage/RecommendationsPreview";
import InfoWall from "components/pages/LandingPage/InfoWall";
import Glow from "components/Glow";

// Assets
import GlowPurple from "assets/img/glow_purple.png";
import GlowPurpleHalf from "assets/img/glow_purple_half.png";
import GlowYellow from "assets/img/glow_yellow.png";
import GlowPink from "assets/img/glow_pink.png";
import GlowGreen from "assets/img/glow_green.png";
import CallToAction from "components/pages/LandingPage/CallToAction";
import Section from "components/Section";
import Footer from "components/pages/LandingPage/Footer";

function LandingPage() {
  return (
    <div className="relative h-screen w-screen overflow-x-hidden">
      <Header>
        <div className="flex flex-row items-center justify-between pt-8">
          <LogoFull className="h-fit w-48" />
          <AppButton>
            <p className="font-bold">&nbsp;&nbsp;Launch DriftPlanner&nbsp;→</p>
          </AppButton>
        </div>
      </Header>

      <Container>
        <Section className="mt-60">
          <Glow
            image={GlowPurple}
            alt="Glow Purple"
            className="w-[200vw] -translate-y-96 md:w-[180vw] lg:w-[90vw]"
          />
          <HeroSection />
        </Section>

        <Section className="mt-40">
          <TagLine />
        </Section>

        <Section className="mt-48">
          <Glow
            image={GlowYellow}
            alt="Glow Yellow"
            className="h-[130vh] xs:h-[150vh] sm:h-[170vh] md:h-[130vh] w-[200vw] -translate-y-[20vh] md:w-[180vw] lg:w-[90vw]"
          />
          <RecommendationsPreview />
        </Section>

        <Section className="mt-80">
          <Glow
            image={GlowPink}
            alt="Glow Pink"
            className="w-[200vw] -translate-y-72 md:-translate-y-96 md:w-[180vw] lg:w-[130vw]"
          />
          <AppPreview />
        </Section>

        <Section className="mt-80">
          <Glow
            image={GlowGreen}
            alt="Glow Green"
            className="lg:h-[110vh] h-[70vh] sm:h-[100vh] w-[140vw] -translate-y-64 opacity-50 md:w-[180vw] lg:w-[90vw]"
          />
          <InfoWall />
        </Section>
      </Container>

      <Section className="mt-80 relative">
        {/* Glow needs to be absolute anchored to the bottom to make sure it isn't hovering above the footer. */}
        <Glow
          image={GlowPurpleHalf}
          alt="Glow Purple"
          className="absolute bottom-0 w-[200vw] md:w-[180vw] lg:w-[90vw]"
        />
        <CallToAction />
      </Section>
      <Footer />
    </div>
  );
}

export default LandingPage;
