import LocationCard from "./LocationCard";
import IconTextTitle from "components/IconTextTitle";

import Brain from "assets/img/brain.png";
import Avatar from "assets/img/avatar.png";
import Globe from "assets/img/globe.png";
import Tbilisi from "assets/img/tbilisi.png";
import Belgrade from "assets/img/belgrade.png";
import Bucharest from "assets/img/bucharest.png";

export default function RecommendationsPreview() {
  return (
    <div
      id="recommendationsPreview"
      className="flex w-full flex-col items-center"
    >
      <IconTextTitle
        image={Brain}
        alt="Brain"
        text="Intelligent Recommendations"
        description="Customized recommendations on where to travel to next based on your current location and preferences."
      />

      <div className="mt-16 flex w-full flex-row justify-center">
        <img src={Avatar} alt="Avatar" className="h-20 w-20" />
        <div className="ml-4 flex flex-col justify-center">
          <p className="text-3xl xs:text-2xl font-bold">📍Istanbul, Turkey</p>
          <p className="text-3xl xs:text-2xl">12 days left on visa exemption</p>
          <p className="text-2xl xs:text-xl">Exit Turkey on June 17th</p>
        </div>
      </div>

      <div className="mt-12 flex flex-row justify-center">
        <img src={Globe} alt="Globe" className="h-auto w-12" />
        <div className="ml-4 flex flex-col justify-center">
          <p className="text-3xl font-bold">Here’s where we recommend next...</p>
        </div>
      </div>

      <div className="mt-12 flex flex-col md:flex-row space-y-8 md:space-y-0 items-start md:space-x-12">
        <LocationCard
          image={Tbilisi}
          country="Georgia"
          city="Tbilisi"
          visa="30d exempt"
          temperature="25°C"
          timezone="UTC+4"
          quick_flight="2h $110"
          hotel_price="$65"
          airbnb_price="$50"
        />
        <LocationCard
          image={Belgrade}
          country="Serbia"
          city="Belgrade"
          visa="30d exempt"
          temperature="21°C"
          timezone="UTC+1"
          quick_flight="1.5h $90"
          hotel_price="$55"
          airbnb_price="$60"
        />
        <LocationCard
          image={Bucharest}
          country="Romania"
          city="Bucharest"
          visa="30d exempt"
          temperature="23°C"
          timezone="UTC+2"
          quick_flight="1h $61"
          hotel_price="$48"
          airbnb_price="$65"
        />
      </div>
    </div>
  );
}
