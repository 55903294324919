import Files from "assets/img/files.png";
import InfoTiles from "assets/img/tiles.png";
import IconTextTitle from "components/IconTextTitle";

export default function RecommendationsPreview() {
  return (
    <div  className="flex w-full flex-col items-center">
      <IconTextTitle
        image={Files}
        alt="Files"
        text="All The Info You Could Ever Want"
        description="Everything from which cultural landmarks are a must see, all the way to what electrical plug types you’ll need."
      />
      <img src={InfoTiles} alt="Info Tiles" className="mt-8"/>
    </div>
  );
}
