import LogoFull from "components/LogoFull";

export default function Footer() {
  return (
    <div className="absolute z-50 w-screen border-t border-[#585858] bg-[#101010]">
      <div className="mb-12 mt-20 flex h-auto flex-col items-center justify-center">
        <LogoFull className="mb-12 w-64" />
        <p>©2024. DriftPlanner. All rights reserved</p>
      </div>
    </div>
  );
}
